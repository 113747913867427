import request from '@/utils/requestV2'
const qs = require('qs')

// 获取销售积分排名统计
export function getSalerPointList (data) {
  return request({
    url: '/ooh-sf/user/getsalerpointlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取可分配的地推人员
export function getAssignablePromoterList (data) {
  return request({
    url: '/ooh-sf/user/getassignablepromoterlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 为小微销售分配地推人员
export function assginPromoter (data) {
  return request({
    url: '/ooh-sf/user/assginpromoter',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除地推人员
export function cancelPromoter (data) {
  return request({
    url: '/ooh-sf/user/cancelpromoter',
    method: 'post',
    data: qs.stringify(data)
  })
}
