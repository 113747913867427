<template>
  <div>
    <div class="m-b-10">
      <RadioGroup
        v-model="dateType"
        type="button"
        size="small"
        @on-change="handleChangeDateType"
      >
        <Radio
          style="width:64.5px;text-align:center"
          v-for="item in dateTypeArray"
          :key="'dateType_'+item.value"
          :label="item.value"
        >{{item.name}}</Radio>
      </RadioGroup>
      <h4 class="workplatform-title m-t-10 m-b-10">销售排名</h4>
      <div class="p-b-5">
        <p class="text-14"><span class="text-orange">提示：</span><span>点击名称可查看该人员的数据信息。</span></p>
      </div>
      <Table
        stripe
        size="small"
        :data="integralList"
        :columns="integralColumns"
      ></Table>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">售卖概览</h4>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col
          span="12"
          class="text-center"
        >
          <div class="text-16">{{businessVisitData}}</div>
          <div>拜访商家数</div>
        </i-col>
        <i-col
          span="12"
          class="text-center"
        >
          <div class="text-16">{{businessSignData}}</div>
          <div>成交商家数</div>
        </i-col>
      </Row>
      <Row :gutter="8">
        <i-col
          span="8"
          class="text-center"
        >
          <div class="text-16">{{salerSummaryData.amount}}</div>
          <div>销量</div>
        </i-col>
        <i-col
          span="8"
          class="text-center"
        >
          <div class="text-16 money">{{formatMoney(salerSummaryData.signMoney)}}</div>
          <div>签约金额</div>

        </i-col>
        <i-col
          span="8"
          class="text-center"
        >
          <div class="text-16 text-red">{{formatMoney(salerSummaryData.refundMoney)}}</div>
          <div>退款金额</div>
        </i-col>
      </Row>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">开发区域概览</h4>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col
          span="8"
          class="text-center"
        >
          <div class="text-16">{{cellSummaryData.alreadyCount}}</div>
          <div>开发网格数</div>
        </i-col>
        <i-col
          span="8"
          class="text-center"
        >
          <div class="text-16">{{cellSummaryData.proportion}}</div>
          <div>占总网格比例</div>
        </i-col>
        <i-col
          span="8"
          class="text-center"
        >
          <div class="text-16">{{cellSummaryData.averageDevelopRate?cellSummaryData.averageDevelopRate+'%':null}}</div>
          <div>开发强度</div>
        </i-col>
      </Row>
      <Row :gutter="8">
        <i-col
          span="8"
          class="text-center"
          v-for="item in showBusinessSummary"
          :key="'business_'+item.status"
        >
          <div class="text-16">{{item.count}}</div>
          <div>{{item.statusName}}</div>
        </i-col>
      </Row>
    </div>
    <!-- <div>
      <Row
        :gutter="8"
        class=" m-t-10 m-b-10"
      >
        <i-col span="12">
          <h4 class="workplatform-title">地推人员</h4>
        </i-col>
        <i-col
          span="12"
          class="text-right"
        >
          <Button
            v-if="selectedUserId"
            type="success"
            size="small"
            @click="handleAddPromoter"
          >新增地推人员</Button>
        </i-col>
      </Row>

      <Table
        stripe
        size="small"
        :data="promotionSummaryData"
        :columns="promotionColumns"
      ></Table>
    </div> -->
    <Modal
      v-model="addPromoterModal"
      @on-ok="handleSubmitAddPromoter"
    >
      <p
        slot="header"
        class="text-center"
      >新增地推人员</p>
      <div>
        <h4 class="m-b-10">选择可分配的地推人员</h4>
        <div v-if="availablePromoterList&&availablePromoterList.length">
          <RadioGroup v-model="newPromoterId">
            <Radio
              v-for="item in availablePromoterList"
              size="small"
              :key="'promoter_'+item.promoterId"
              :label="item.promoterId"
              border
            >{{item.promoterName}}</Radio>
          </RadioGroup>
        </div>
        <div v-else>当前没有可分配的地推人员。</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getSalerPointList, getAssignablePromoterList, assginPromoter } from '@/api/sf/user'
import { getBusinessStatusSummary, getCellSummary, getVisitCount, getSignCount } from '@/api/sf/statistic'
// import { getSaleSummary } from '@/api/order/miniorder'
import { toMoney } from '@/utils/wnumb_own'
import { getStorage } from '@/utils/storage'

export default {
  data () {
    return {
      dateTypeArray: [
        { value: 1, name: '日' },
        { value: 2, name: '周' },
        { value: 3, name: '月' },
        { value: 4, name: '季' },
        { value: 5, name: '年' }
      ],
      dateType: 1,
      startDate: '',
      endDate: '',
      selectedUserId: null,
      integralList: [],
      integralColumns: [
        {
          title: '名称',
          align: 'left',
          key: 'name',
          width: 85,
          ellipsis: true,
          tooltip: true,
          render: (h, params) => {
            return h('a', {
              style: { color: this.selectedUserId === params.row.userId ? '#1E90FF' : this.reportFontColor },
              on: {
                click: () => {
                  this.handleChoiceSaler(params.row)
                }
              }
            }, params.row.name)
          }
        },
        { title: '累计积分', align: 'center', width: 85, key: 'yearPoint' },
        { title: '积分', align: 'center', key: 'periodPoint' },
        {
          title: '环比',
          align: 'center',
          width: 80,
          render: (h, params) => {
            if (params.row.lastPeriodPoint === 0) {
              // 如果上期为0，则不进行比较
              return h('span', '-')
            } else {
              const diff = params.row.periodPoint - params.row.lastPeriodPoint
              const ratio = diff === 0 ? 0 : parseFloat((Math.abs(diff) / params.row.lastPeriodPoint * 100).toFixed(2))

              if (diff === 0) {
                return h('span', `${ratio}%`)
              } else if (diff > 0) {
                return h('span', {
                  style: { color: '#ef4f4f' }
                }, `${ratio}%`)
              } else {
                return h('span', {
                  style: { color: '#19be6b' }
                }, `-${ratio}%`)
              }
            }
          }
        }
      ],
      businessSignData: 0, // 商家签约数
      businessVisitData: 0, // 销售拜访商家数
      businessSummaryData: [], // 商家概览数据
      salerSummaryData: {},
      cellSummaryData: {}, // 网格概览数据
      promotionSummaryData: [], // 地推人员概览数据
      promotionColumns: [
        { title: '名称', align: 'left', key: 'name', width: 90, ellipsis: true, tooltip: true },
        { title: '网格数', align: 'center', key: 'cellCount' },
        {
          title: '已完成/总数',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.finishedBusinessCount}/${params.row.totalBusinessCount}`)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return this.selectedUserId ? h('a', {
              style: { color: '#ef4f4f' },
              on: {
                click: () => {
                  this.handleDeletePromoter(params.row)
                }
              }
            }, '删除') : null
          }
        }
      ],
      addPromoterModal: false,
      availablePromoterList: [],
      newPromoterId: null // 新增地推人员id
    }
  },
  computed: {
    showBusinessSummary () {
      return this.businessSummaryData.filter(x => x.status > 0)
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  created () {
    this.getSalerIntegralSort()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    /**
     * 获取销售积分排名
     */
    getSalerIntegralSort () {
      getSalerPointList({ period: this.dateType }).then(res => {
        if (res && !res.errcode) {
          this.integralList = res
          this.startDate = this.integralList[0].startDate
          this.endDate = this.integralList[0].endDate

          this.$store.commit('set_start_date', this.startDate)
          this.$store.commit('set_end_date', this.endDate)

          this.getBusinessSummayData()
          this.getCellSummayData()
          // this.getPromotionSummaryData()
          this.getSalerSummaryData()
          this.getBusinessVisitData()
          this.getBusinessSignData()
        } else {
          this.integralList = []
          this.startDate = ''
          this.endDate = ''
        }
        this.$store.commit('set_user_array', this.integralList)
      })
    },
    handleChangeDateType () {
      this.$store.commit('set_start_date', this.startDate)
      this.$store.commit('set_end_date', this.endDate)
      this.getSalerIntegralSort()
      this.getBusinessVisitData()
      this.getBusinessSignData()
    },
    /**
     * 选择销售事件
     */
    handleChoiceSaler (params) {
      this.selectedUserId = this.selectedUserId === params.userId ? null : params.userId
      this.$store.commit('set_user_id', this.selectedUserId)
      this.getSalerSummaryData()
      this.getCellSummayData()
      this.getBusinessVisitData()
      this.getBusinessSignData()
      // this.getPromotionSummaryData()
      this.getBusinessSummayData()
    },
    /**
     * 获取拜访商家情况数据
     */
    getBusinessVisitData () {
      getVisitCount({ startDate: this.startDate, endDate: this.endDate, userId: this.selectedUserId }).then(res => {
        this.businessVisitData = res
      })
    },
    /**
     * 获取销售签约商家数
     */
    getBusinessSignData () {
      getSignCount({ startDate: this.startDate, endDate: this.endDate, userId: this.selectedUserId }).then(res => {
        this.businessSignData = res
      })
    },
    /**
     * 获取商家概览
     */
    getBusinessSummayData () {
      getBusinessStatusSummary({ userId: this.selectedUserId }).then(res => {
        if (res && !res.errcode) {
          this.businessSummaryData = res
        }
      })
    },
    /**
     * 获取金额数据
     */
    // getSalerSummaryData () {
    //   getSaleSummary({ startDate: this.startDate, endDate: this.endDate, userId: this.selectedUserId }).then(res => {
    //     if (res && !res.errcode) {
    //       this.salerSummaryData = res
    //     }
    //   })
    // },
    /**
     * 获取网格概览数据
     */
    getCellSummayData () {
      getCellSummary({ userId: this.selectedUserId }).then(res => {
        if (res && !res.errcode) {
          const proportion = res.alreadyCount === 0 || res.totalCount === 0 ? 0 : parseFloat((res.alreadyCount / res.totalCount * 100).toFixed(2))
          this.cellSummaryData = Object.assign({ proportion: `${proportion}%` }, res)
        }
      })
    },
    // /**
    //  * 获取地推人员概览数据
    //  */
    // getPromotionSummaryData () {
    //   getPromotionSummary({ userId: this.selectedUserId }).then(res => {
    //     if (res && !res.errcode) {
    //       this.promotionSummaryData = res
    //     }
    //   })
    // },
    /**
     * 获取可分配的地推人员集合
     */
    getAvailablePromoterData () {
      getAssignablePromoterList().then(res => {
        this.availablePromoterList = res
      })
    },
    handleAddPromoter () {
      this.getAvailablePromoterData()
      this.addPromoterModal = true
    },
    handleSubmitAddPromoter () {
      if (!this.newPromoterId) {
        this.$Notice.warning({ desc: '请选择一个地推人员' })
        return false
      }
      if (!this.selectedUserId) {
        this.$Notice.warning({ desc: '当前未选择销售人员' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要将该地推人员分配给当前销售？',
        onOk: () => {
          assginPromoter({ promoterId: this.newPromoterId, salerId: this.selectedUserId }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.newPromoterId = null
              // this.getPromotionSummaryData()
            }
          })
        }
      })
    }
    // handleDeletePromoter (params) {
    //   this.$Modal.confirm({
    //     title: '操作提示',
    //     content: '确定要删除该地推人员？同时会删除该地推人员已分配网格。',
    //     onOk: () => {
    //       cancelPromoter({ salerId: this.selectedUserId, promoterId: params.userId }).then(res => {
    //         if (res && res.errcode === 0) {
    //           this.getPromotionSummaryData()
    //           this.$Notice.success({ desc: '操作成功' })
    //         }
    //       })
    //     }
    //   })
    // }
  }
}
</script>
