import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取商家概况
 * @param {Object} data
 * @returns
 */
export function getBusinessStatusSummary (data) {
  return request({
    url: '/ooh-sf/statistic/getbusinessstatussummary',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取网格概况
 * @param {Object} data
 * @returns
 */
export function getCellSummary (data) {
  return request({
    url: '/ooh-sf/statistic/getcellsummary',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取地推人员完成情况统计
 * @param {Object} data
 * @returns
 */
export function getPromotionSummary (data) {
  return request({
    url: '/ooh-sf/statistic/getpromotionsummary',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取销售拜访商家数
 * @param {Object} data
 * @returns
 */
export function getVisitCount (data) {
  return request({
    url: '/ooh-sf/statistic/getvisitcount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取销售签约商家数
 * @param {Object} data
 * @returns
 */
export function getSignCount (data) {
  return request({
    url: '/ooh-sf/statistic/getsigncount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取销售拜访笔记数
 * @param {Object} data
 * @returns
 */
export function getNoteCount (data) {
  return request({
    url: '/ooh-sf/statistic/getnotecount',
    method: 'post',
    data: qs.stringify(data)
  })
}
